import { Component, OnInit } from "@angular/core";
import { CommonService } from "../services/common.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-extension",
  template: `<div class="contants container">
    <!-- <blockquote class="blockquote bq-primary"> -->
    <h4 class="section-heading h4 pt-4">Extension Studies</h4>
    <br />
    <p class="lead text-justify">
      For the extension studies courses, training will be given to the
      interested candidates by our faculty in their own cities or towns if
      minimum fifteen candidates are willing to undergo such program at one
      particular centre. More information about Extension Education and Distance
      Education will be sent upon request.Please send your query to
      ycltseminary@gmail.com.
    </p>
    <br />
    <!-- </blockquote> -->
    <div class="row">
      <blockquote
        class="blockquote alternate-bq col-12 col-md-6 col-lg-6 col-xl-4"
        *ngFor="let course of courses | async"
      >
        <h6 class="section-heading h6 bq-title">{{ course.name }}</h6>
        <span class="grey-text" *ngIf="course.duration != undefined"
          >Duration</span
        >
        <span *ngIf="course.duration != undefined"
          >: {{ course.duration }}<br
        /></span>
        <span class="grey-text" *ngIf="course.qualification != undefined"
          >Required Qualification</span
        >
        <span *ngIf="course.qualification != undefined">
          : {{ course.qualification }} <br
        /></span>
        <span class="grey-text" *ngIf="course.affiliation != undefined"
          >Affiliation</span
        >
        <span *ngIf="course.affiliation != undefined">
          : {{ course.affiliation }} <br
        /></span>
        <small class="grey-text" *ngIf="course.hint != undefined">
          <em [innerHTML]="course.hint"> </em>
        </small>
      </blockquote>
    </div>
    <br />
    <p class="text-justify">
      Download the prospectus by
      <a href="assets/admissions/Prospectus E.pdf">clicking here.</a>
    </p>
    <p class="text-justify">
      To apply for any of the courses listed above, please send the completed
      admission form to
      <a href="mailto:ycltseminary@gmail.com">ycltseminary@gmail.com</a>. To
      download the admission form and fees structure,
      <a href="assets/ApplicationForm.pdf" target="blank">click here</a> or
      apply online by
      <a href="//forms.gle/EJA4PvQ97dQKk3Xg6" target="blank">clicking here</a>.
    </p>
  </div> `,
})
export class ExtensionComponent implements OnInit {
  courses: Observable<any[]>;
  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.courses = this.commonService.getExtensionCourses();
  }
}
