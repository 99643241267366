import { Component, OnInit, ViewChild } from "@angular/core";
// import { CalendarComponent } from "@fullcalendar/angular";
// import { Options } from "fullcalendar";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent implements OnInit {
  // calendarOptions: Options;
  // @ViewChild(CalendarComponent, { static: true }) ucCalendar: CalendarComponent;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  title;
  calendarVisible = true;
  calendarPlugins = [dayGridPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = [
    { title: "College Re-opens after X’mas holidays", start: "2018-01-04" },
    { title: "Arrival of Students", start: "2018-01-05" },
    { title: "Registration for 3rd term", start: "2018-01-06" },
    { title: "Classes Commence", start: "2018-01-08" },
    { title: "Pastoral Care Group Fellowship", start: "2018-01-10" },
    { title: "YCLT Annual Sports Day", start: "2018-01-19" },
    { title: "Republic Day", start: "2018-01-26" },
    {
      title: "Mission Conference/Mission Fete",
      start: "2018-02-02",
      end: "2018-02-04",
    },
    {
      title: "Last day for Registration forSupplementary/Retake (IIICS Exam)",
      start: "2018-02-05",
    },
    { title: "Day of fasting and prayer", start: "2018-02-13" },
    { title: "Ash Wednesday", start: "2018-02-14" },
    { title: "Pastoral care Group Dinner", start: "2018-02-22" },
    { title: "Bible Quiz", start: "2018-02-25" },
    {
      title: "IIICS Examination (Supplementary/Retake)",
      start: "2018-03-01",
      end: "2018-03-03",
    },
    { title: "Parting Social", start: "2018-03-11" },
    { title: "Graduating Class Sunday", start: "2018-03-11" },
    { title: "Last day of Classes for 3rd term", start: "2018-03-14" },
    { title: "Commissioning Service", start: "2018-03-18" },
    { title: "College Exams", start: "2018-03-20", end: "2018-03-24" },
    { title: "Palm Sunday", start: "2018-03-25" },
    {
      title: "Students leave for summer ministry and Vacation",
      start: "2018-03-26",
    },
    { title: "Maundy Thursday", start: "2018-03-29" },
    { title: "Good Friday", start: "2018-03-30" },
    { title: "Silent Saturday", start: "2018-03-31" },
    { title: "Easter Sunday", start: "2018-04-01" },
    {
      title: "Seminar for Extension Students",
      start: "2018-04-05",
      end: "2018-04-15",
    },
    { title: "Paper corrections", start: "2018-04-15", end: "2018-04-20" },
    {
      title: "Faculty Field visit/Promotion Trip",
      start: "2018-04-23",
      end: "2018-04-30",
    },
    {
      title: "Summer Vacation for staff",
      start: "2018-05-01",
      end: "2018-06-10",
    },
    { title: "College Reopens", start: "2018-06-11" },
    {
      title: "Preparation for New Academic Year",
      start: "2018-06-11",
      end: "2018-06-14",
    },
    { title: "Staff Retreat", start: "2018-06-15", end: "2018-06-16" },
    { title: "Arrival of Students", start: "2018-06-23" },
    { title: "First Sunday service with Students", start: "2018-06-24" },
    { title: "Registration/Orientation", start: "2018-06-25" },
    { title: "Classes Commence", start: "2018-06-25" },

    { title: "Installation of Committees", start: "2018-07-08" },
    { title: "Welcome Social", start: "2018-07-14" },
    { title: "Sports Inauguration", start: "2018-07-19" },
    {
      title:
        "Last day for Registration - Senate of Serampore College (University)",
      start: "2018-07-15",
    },
    { title: "Pastoral care Group Fellowship", start: "2018-07-27" },
    {
      title:
        "Registration with late fee - Senate of Serampore College (University)",
      start: "2018-07-31",
    },

    { title: "Independence Day", start: "2018-08-15" },
    {
      title: "Spiritual Life Conference and Missionary Project launch",
      start: "2018-08-17",
      end: "2018-08-18",
    },
    { title: "Last day for registration (IIICS)", start: "2018-08-16" },
    {
      title:
        "Last day for exam paper Registration Form Online - Senate of Serampore College (University)",
      start: "2018-08-20",
    },
    {
      title:
        "Last day for exam paper Registration Form printed Copy - Senate of Serampore College (University)",
      start: "2018-08-31",
    },
    {
      title: "Last day for registration with late fee (IIICS)",
      start: "2018-08-31",
    },
    { title: "Teacher’s Day", start: "2018-09-05" },
    {
      title:
        "Exam paper Registration Form with late fee - Senate of Serampore College (University)",
      start: "2018-09-15",
    },
    { title: "Day of Fasting and Prayer", start: "2018-09-20" },
    { title: "Talent Night", start: "2018-09-29" },
    {
      title: "Last day of registration for IIICS Exams",
      start: "2018-09-30",
    },

    { title: "Gandhi Jayanti", start: "2018-10-02" },
    { title: "Debate", start: "2018-10-12" },
    {
      title: "Last day for Registration for IIICS examinations with late fee",
      start: "2018-10-15",
    },
    { title: "Field Hiking", start: "2018-10-19" },
    { title: "Last day of Classes for 1st semester", start: "2018-10-26" },
    { title: "Pastoral Care Group Dinner", start: "2018-10-26" },

    {
      title:
        "1st Semester Examination - Senate of Serampore College (University)",
      start: "2018-11-01",
      end: "2018-11-10",
    },
    {
      title: "Seminar for Extension Students",
      start: "2018-11-05",
      end: "2018-11-15",
    },
    { title: "Registration for 2nd Semester", start: "2018-11-13" },
    { title: "Classes commence for 2nd semester", start: "2018-11-14" },
    { title: "YCLT Picnic", start: "2018-11-17" },
    { title: "IIICS Examination", start: "2018-11-24", end: "2018-11-30" },

    { title: "Carol Round", start: "2018-12-14" },
    { title: "Christmas Service", start: "2018-12-15" },
    { title: "Students leave for Christmas Vacation", start: "2018-12-16" },
    {
      title: "Christmas Vacation for Students",
      start: "2018-12-16",
      end: "2019-01-06",
    },
    {
      title: "Faculty Field visit & promotion trip",
      start: "2018-12-17",
      end: "2019-12-22",
    },
    {
      title: "Christmas Vacation (Tentative)",
      start: "2018-12-22",
      end: "2019-01-03",
    },
  ];
  constructor() {}
  /* viewRender() {
    let el = [
      { title: "College Re-opens after X’mas holidays", start: "2018-01-04" },
      { title: "Arrival of Students", start: "2018-01-05" },
      { title: "Registration for 3rd term", start: "2018-01-06" },
      { title: "Classes Commence", start: "2018-01-08" },
      { title: "Pastoral Care Group Fellowship", start: "2018-01-10" },
      { title: "YCLT Annual Sports Day", start: "2018-01-19" },
      { title: "Republic Day", start: "2018-01-26" },
      {
        title: "Mission Conference/Mission Fete",
        start: "2018-02-02",
        end: "2018-02-04",
      },
      {
        title: "Last day for Registration forSupplementary/Retake (IIICS Exam)",
        start: "2018-02-05",
      },
      { title: "Day of fasting and prayer", start: "2018-02-13" },
      { title: "Ash Wednesday", start: "2018-02-14" },
      { title: "Pastoral care Group Dinner", start: "2018-02-22" },
      { title: "Bible Quiz", start: "2018-02-25" },
      {
        title: "IIICS Examination (Supplementary/Retake)",
        start: "2018-03-01",
        end: "2018-03-03",
      },
      { title: "Parting Social", start: "2018-03-11" },
      { title: "Graduating Class Sunday", start: "2018-03-11" },
      { title: "Last day of Classes for 3rd term", start: "2018-03-14" },
      { title: "Commissioning Service", start: "2018-03-18" },
      { title: "College Exams", start: "2018-03-20", end: "2018-03-24" },
      { title: "Palm Sunday", start: "2018-03-25" },
      {
        title: "Students leave for summer ministry and Vacation",
        start: "2018-03-26",
      },
      { title: "Maundy Thursday", start: "2018-03-29" },
      { title: "Good Friday", start: "2018-03-30" },
      { title: "Silent Saturday", start: "2018-03-31" },
      { title: "Easter Sunday", start: "2018-04-01" },
      {
        title: "Seminar for Extension Students",
        start: "2018-04-05",
        end: "2018-04-15",
      },
      { title: "Paper corrections", start: "2018-04-15", end: "2018-04-20" },
      {
        title: "Faculty Field visit/Promotion Trip",
        start: "2018-04-23",
        end: "2018-04-30",
      },
      {
        title: "Summer Vacation for staff",
        start: "2018-05-01",
        end: "2018-06-10",
      },
      { title: "College Reopens", start: "2018-06-11" },
      {
        title: "Preparation for New Academic Year",
        start: "2018-06-11",
        end: "2018-06-14",
      },
      { title: "Staff Retreat", start: "2018-06-15", end: "2018-06-16" },
      { title: "Arrival of Students", start: "2018-06-23" },
      { title: "First Sunday service with Students", start: "2018-06-24" },
      { title: "Registration/Orientation", start: "2018-06-25" },
      { title: "Classes Commence", start: "2018-06-25" },

      { title: "Installation of Committees", start: "2018-07-08" },
      { title: "Welcome Social", start: "2018-07-14" },
      { title: "Sports Inauguration", start: "2018-07-19" },
      {
        title:
          "Last day for Registration - Senate of Serampore College (University)",
        start: "2018-07-15",
      },
      { title: "Pastoral care Group Fellowship", start: "2018-07-27" },
      {
        title:
          "Registration with late fee - Senate of Serampore College (University)",
        start: "2018-07-31",
      },

      { title: "Independence Day", start: "2018-08-15" },
      {
        title: "Spiritual Life Conference and Missionary Project launch",
        start: "2018-08-17",
        end: "2018-08-18",
      },
      { title: "Last day for registration (IIICS)", start: "2018-08-16" },
      {
        title:
          "Last day for exam paper Registration Form Online - Senate of Serampore College (University)",
        start: "2018-08-20",
      },
      {
        title:
          "Last day for exam paper Registration Form printed Copy - Senate of Serampore College (University)",
        start: "2018-08-31",
      },
      {
        title: "Last day for registration with late fee (IIICS)",
        start: "2018-08-31",
      },
      { title: "Teacher’s Day", start: "2018-09-05" },
      {
        title:
          "Exam paper Registration Form with late fee - Senate of Serampore College (University)",
        start: "2018-09-15",
      },
      { title: "Day of Fasting and Prayer", start: "2018-09-20" },
      { title: "Talent Night", start: "2018-09-29" },
      {
        title: "Last day of registration for IIICS Exams",
        start: "2018-09-30",
      },

      { title: "Gandhi Jayanti", start: "2018-10-02" },
      { title: "Debate", start: "2018-10-12" },
      {
        title: "Last day for Registration for IIICS examinations with late fee",
        start: "2018-10-15",
      },
      { title: "Field Hiking", start: "2018-10-19" },
      { title: "Last day of Classes for 1st semester", start: "2018-10-26" },
      { title: "Pastoral Care Group Dinner", start: "2018-10-26" },

      {
        title:
          "1st Semester Examination - Senate of Serampore College (University)",
        start: "2018-11-01",
        end: "2018-11-10",
      },
      {
        title: "Seminar for Extension Students",
        start: "2018-11-05",
        end: "2018-11-15",
      },
      { title: "Registration for 2nd Semester", start: "2018-11-13" },
      { title: "Classes commence for 2nd semester", start: "2018-11-14" },
      { title: "YCLT Picnic", start: "2018-11-17" },
      { title: "IIICS Examination", start: "2018-11-24", end: "2018-11-30" },

      { title: "Carol Round", start: "2018-12-14" },
      { title: "Christmas Service", start: "2018-12-15" },
      { title: "Students leave for Christmas Vacation", start: "2018-12-16" },
      {
        title: "Christmas Vacation for Students",
        start: "2018-12-16",
        end: "2019-01-06",
      },
      {
        title: "Faculty Field visit & promotion trip",
        start: "2018-12-17",
        end: "2019-12-22",
      },
      {
        title: "Christmas Vacation (Tentative)",
        start: "2018-12-22",
        end: "2019-01-03",
      },
    ];
    for (let i of el) {
      this.ucCalendar.fullCalendar("renderEvent", i);
    }
    this.ucCalendar.fullCalendar("rerenderEvents");
  } */
  /* eventClick(event) {
    this.title = event.event.title;
  } */
  resetTitle() {
    this.title = "";
  }
  /* clickButton(event) {
    this.ucCalendar.fullCalendar("refetchEvents");
    this.viewRender();
  } */
  handleDateClick(arg) {
    console.log(arg);
    /* if (confirm("Would you like to add an event to " + arg.dateStr + " ?")) {
      this.calendarEvents = this.calendarEvents.concat({
        // add new event data. must create new array
        title: "New Event",
        start: arg.date,
        allDay: arg.allDay,
      });
    } */
  }
  ngOnInit() {
    /*  (this.calendarOptions = {}),
      (this.calendarOptions = {
        editable: true,
        eventLimit: false,
        header: {
          left: "prev,next today",
          center: "title",
          right: "month,agendaWeek,agendaDay,listMonth",
        },
      }); */
  }
}
