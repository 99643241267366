<div class="contants container">
  <section class="section team-section pb-3">
    <h4 class="section-heading h4 pt-4 bq-title">YCLT Board Members 2015-2018</h4>
    <br>
    <div class="row">
      <blockquote class="blockquote alternate-bq col-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let member of boardMembers">
        <h4 class="section-heading h4 pt-4 bq-title">{{member.name}}</h4>
        <h6 class="font-bold">{{member.designation}}</h6>
        <h6 class="font-bold">{{member.designation2}}</h6>
        <h6 class="font-bold">{{member.designation3}}</h6>
      </blockquote>
    </div>
  </section>
</div>
