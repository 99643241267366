<div style="height: 50px;"></div>
<main>
  <!-- <mdb-carousel [isControls]="true" [animation]="'slide'">
    <mdb-slide *ngFor="let slide of slideShow">
      <img
        class="d-block w-100 show-mobile view hm-indigo-light"
        [src]="slide.imgSrc"
        style="object-fit: cover;"
        [alt]="slide.title"
      />
      <div class="carousel-caption">
        <h3 class="h3-responsive">{{ slide.title }}</h3>
      </div>
    </mdb-slide>
  </mdb-carousel> -->
  <div class="mt-4">
    <gallery
      id="home-gallery"
      [items]="slideShow"
      [thumb]="false"
      [itemTemplate]="itemTemplate"
      [autoPlay]="true"
      [counter]="false"
    ></gallery>
  </div>
  <ng-template
    #itemTemplate
    let-index="index"
    let-type="type"
    let-data="data"
    let-currIndex="currIndex"
  >
    <span *ngIf="type === 'image' && index === currIndex" class="item-text">
      {{ data?.title }}
    </span>
  </ng-template>
  <br />
  <!-- <div class="text-center text-justify" style="word-break: break-all;padding-left: 50px;padding-right: 50px"> -->
  <div class="text-center text-justify">
    <em>
      <div id="ourmanna-verse">
        <div id="mannaverse-container">
          <p id="mannaverse">
            For God so loved the world that he gave his one and only Son, that
            whoever believes in him shall not perish but have eternal life.
          </p>
          <p id="mannaverse-reference">
            John 3:16 <small id="mannaverse-version"> <i>(NIV)</i> </small>
          </p>
        </div>
      </div>
    </em>
    <!-- <em>
      <h6 id="ourmanna-verse">
      </h6>
    </em> -->
  </div>
  <br />
  <div class="row col-12">
    <div class="col-lg-6 col-md-6 mb-r" style="padding-left: 50px;">
      <div>
        <blockquote class="blockquote bq-primary">
          <h5 class="section-heading bq-title">Greetings From Director</h5>
          <br />
          <div class="media mb-1">
            <a class="media-left waves-light">
              <img
                class="rounded-circle"
                style="height: 60%; width: 60%;"
                src="assets/director/Girish.Khatri.JPG"
                alt="Generic placeholder image"
              />
            </a>
            <div class="media-body">
              <h6 class="media-heading">
                <b>Rev. Dr. Girish J. Khatri</b> <br />
                <b>Director, YCLT</b>
              </h6>
            </div>
          </div>
          <p class="text-justify">
            <font size="3"
              >Dear Friends <br />
              Warm Greetings! <br />
              It fills my heart with immense joy, to learn of your interest and
              keenness as you spare few minutes to surf across the pages of YCLT
              website. <br />
              <a
                class="text text-primary relative float-right"
                (click)="basicModal.show()"
                mdbRippleRadius
                >read more...</a
              >
              <div
                mdbModal
                #basicModal="mdb-modal"
                class="modal fade news-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myBasicModalLabel"
                aria-hidden="true"
                [config]="{ backdrop: true, ignoreBackdropClick: true }"
              >
                <div class="modal-dialog modal-fluid" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title w-100" id="myModalLabel">
                        Director's messasge
                      </h4>
                    </div>
                    <div
                      class="modal-body"
                      style="height: 450px; overflow: auto;"
                    >
                      <p class="text-justify" style="padding-right: 8%;">
                        <font size="3"
                          >Dear Friends <br />
                          Warm Greetings! <br />It fills my heart with immense
                          joy, to learn of your interest and keenness as you
                          spare few minutes to surf across the pages of YCLT
                          website. <br />
                          Yavatmal College for Leadership is a seminary
                          established to train and equip cross cultural
                          grass-root level leaders, with the knowledge of
                          missiology, spiritual life development and qcquisition
                          of ministerial skills through field training to
                          fulfill the Great Commission of Jesus Christ. Since
                          its inception, the Seminary has equipped over 1600
                          servant leaders who are serving in India and abroad.
                          <br />
                          Proper training equips the candidates with the tools;
                          keeps the vision undiminished; and lessens the dropout
                          rate. Reaching the unreached people group is the heart
                          beat of YCLT. <br />
                          The Goal of YCLT is missionary training and missionary
                          children education. Training thousands; reaching
                          millions is our vision. <br />
                          YCLT is managed by registered society of
                          inter-denomination churches and mission within India.
                          <br />
                          The College offers B.Miss program which is affiliated
                          to the Senate of Serampore College (University), and
                          B.Th, and M.Div courses which have been affiliated to
                          Indian Institute of InterCultural Studies, Bangalore.
                          And from the academic year 2018 onwards we are going
                          to provide B.Miss. which is under consideration for
                          affiliation to the Senate of Serampore (University).
                          <br />
                          Spiritual life development, studies, discipline,
                          health, nitrous food and providing good facilities are
                          the concerns in the education of missionary children.
                          Christian witness, quality education and disciplined
                          atmosphere are concerns of the school. <br />
                          <br />
                          Looking forward…. <br />
                          Yours in His Grace, <br />
                          <b>Rev. Dr. Girish J. Khatri</b>
                        </font>
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-primary waves-light"
                        aria-label="Close"
                        (click)="basicModal.hide()"
                        mdbRippleRadius
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </font>
          </p>
        </blockquote>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 mb-r" style="padding-left: 50px;">
      <div class="blockquote bq-warning">
        <h5 class="section-heading bq-title">News & Alerts</h5>
        <div class="list-group" style="height: 250px; overflow: auto;">
          <a
            *ngFor="let item of this.newsAndAlerts"
            class="list-group-item list-group-item-action flex-column align-items-start no-border"
            (click)="navigate(item)"
          >
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1">{{ item.title }}</h6>
            </div>
            <small>{{ item.date | date: "dd-MMM-yy" }}</small>
          </a>
        </div>
      </div>
    </div>
  </div>
</main>
<div
  mdbModal
  #newsModal="mdb-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newsModal"
  aria-hidden="true"
  [config]="{ backdrop: true, ignoreBackdropClick: true }"
>
  <div class="modal-dialog news-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="h4-responsive modal-title w-100" id="myModalLabel">
          {{ currentNews.title }}
        </h4>
      </div>
      <div class="modal-body" style="height: 450px; overflow: auto;">
        <p class="text-justify" style="padding-right: 8%;">
          <font size="3">
            <ul>
              <li *ngFor="let item of currentNews.details">
                <span [innerHTML]="item"></span>
              </li>
            </ul>
          </font>
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn primary-color waves-light"
          aria-label="Close"
          (click)="newsModal.hide()"
          mdbRippleRadius
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
