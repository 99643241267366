<div class="contants container">
  <section class="section team-section pb-3">
    <h4 class="section-heading h4 pt-4 bq-title">Administrative Staff</h4>
    <br>
    <div class="row">
      <div class="col-lg-4 col-md-6 mb-lg-4 mb-4 align-items-start" *ngFor="let member of staff">
        <div class="row align-items-start">
          <div class="avatar">
            <img [src]="'assets/administrative-staff/' + member.img" class="rounded z-depth-1" [alt]="member.name">
          </div>
          <div class="col">
            <h5 class="font-weight-bold mb-1">{{member.name}}</h5>
            <div class="orange-text">
              <strong>{{member.designation}}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
