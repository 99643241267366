import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mission-statement",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">Mission Statement</h4>
      <p class="text-justify">
        Yavatmal College for Leadership Training stands for equipping young
        people with a sound theological knowledge, thorough missiological
        training and a strong spiritual formation thereby enabling them to reach
        the unreached people groups with the gospel of Jesus Christ and to
        spread the Church into rural and urban areas.
      </p>
    </blockquote>
  </div>`,
})
export class MissionStatementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
