<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title"> Admission</h4>
    <p class="text-justify">
      YCLT is committed to the promotion of academic excellence. We take studies seriously believing that God wants mature and
      learned leaders with good understanding of His word, ways and world. Students are encouraged to work hard in a disciplined
      manner in the academic standard.</p>
    <p>Interested applicants may download the application form from the websites.</p>
    <p>The application form has to reach to the YCLT academic office before 2nd week of June.</p>
    <p>The academic year begins on the 3rd week of June every year.</p>
    <p>Applicants must be born again Christians willing to follow the teachings of the Lord Jesus Christ, members of a local
      Church where they are in active fellowship and willing to submit themselves for pursuing theological courses.</p>
    <p>
      Admission is open for both men and women.</p>
    <p> The college practices ecumenism and don’t discriminate gender, color and denomination.</p>
    <small> For the Courses and qualification refer to ‘Course offered’</small>
  </blockquote>
</div>
