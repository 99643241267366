import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-yclt-history",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">What is YCLT</h4>
      <p class="text-justify">
        YCLT is an all India based, grassroots level, full-fledged missionary
        training college to train cross cultural workers. Knowledge of
        missiology, spiritual life development, and acquisition of ministerial
        skills through field training are the emphases. Proper training equips
        the candidates with the tools; keeps the vision undiminished; and
        lessens the dropout rate.
        <br />
        Reaching the unreached people groups is the heart beat of YCLT. The goal
        of YCLT is missionary training and missionary children education.
        Training thousands; reaching millions is our vision. <br />
        YCLT is managed by a registered society of inter-denominational churches
        and missions within India. YCLT administers a College for missionary
        training, a School, and a Home for missionary children education. The
        College is affiliated to Indian Institute Intercultural studies.
      </p>
    </blockquote>
    <blockquote class="blockquote bq-warning">
      <h4 class="section-heading h4 pt-4 bq-title">Founding of YCLT</h4>
      <p class="text-justify">
        During the sixties of the last century a great missionary awakening came
        to the Church in India and during the seventies many cross cultural
        workers were sent out. This trend continues even today. Yavatmal
        consultation for Missionary Training was called for in August 1983.
        Several leaders from churches and missionary societies gathered together
        and studied the possibility of founding a missionary training college
        which would train cross cultural workers. The need for the missionary
        children education was also voiced. They met four times during that
        year. Thus Yavatmal College for Leadership Training came into existence
        in July 1984. The founding of YCLT is a unique event in the history of
        missionary work in India.
      </p>
    </blockquote>
  </div>`,
})
export class YcltHistoryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
