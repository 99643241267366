<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title">Married Person</h4>
    <p class="text-justify">
      Married students are not allowed to use heavy electrical appliances of any kind such as Water heater, electric stove/microwaves,
      iron box, television, stereo attached CD player, refrigerator etc. in their quarters</p>
    <p>Married students must take care of their furniture and equipments.</p>
    <p>Married students should not entertain single students in their homes.</p>
    <p>Married students should avoid visiting hostels frequently.</p>
    <p>Kindly keep your quarters and the surroundings neat and clean.</p>
  </blockquote>
</div>
