import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auxiliaries',
  templateUrl: './auxiliaries.component.html',
  styleUrls: ['./auxiliaries.component.scss']
})
export class AuxiliariesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
