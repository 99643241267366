<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title"> Hostel Life and Discipline</h4>
    <p class="text-justify">
      Personal hygiene and cleanliness are very important. Students are expected to keep their hostel, rooms, quarters, toilets,
      bathrooms and surroundings neat and tidy.</p>
    <p> Be economical in using water and electricity.</p>
    <p> Students are not allowed to use high voltage electrical appliances such as Water heater, electric stove, iron box,
      stereo attached CD player, etc.</p>
    <p>
      Use of tobacco in any form (chewed, smoked), eating beetle nut, gutka or any such stuff, drugs of any kind or use of any
      intoxicants is strictly prohibited. </p>
    <p>Avoid unnecessary noises such as loud music, whistling, howling, screaming etc. in hostel premises/class rooms/library/chapel
      etc.
    </p>
    <p> Strictly avoid making unwanted and unhealthy comments on face book/WhatsApp
    </p>
    <p> Students are not allowed to go out of campus without prior permission except during the allotted days & time.</p>
    <p>
      Cooking in hostel/hostel premise is not allowed.</p>
    <p> Groupism of any kind among students will be dealt with seriously.</p>
    <p> Modesty in dress and in hair style is essential
    </p>
  </blockquote>
</div>
