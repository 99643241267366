import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [CommonService]
})
export class ContactComponent implements OnInit {
  contactForm: UntypedFormGroup;
  data;
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
  ) {
    this.contactForm = this.fb.group({
      name: '',
      email: '',
      message: '',
      subject: '',
      mobileNumber: ''
    });
  }
  sendMail() {
    let form = {
      name: this.contactForm.controls['name'].value,
      email: this.contactForm.controls['email'].value,
      message: this.contactForm.controls['message'].value,
      subject: this.contactForm.controls['subject'].value,
      mobileNumber: this.contactForm.controls['mobileNumber'].value
    };
    this.commonService.sendMail(form).subscribe(res => this.data == res);
    this.contactForm.reset(this.data)
  }
  ngOnInit() {
  }
}
