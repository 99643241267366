import { Component, OnInit } from "@angular/core";
import { routes } from "./routes";
import { Routes } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  verse: string;
  navLinks: Routes;
  constructor() {
    this.navLinks = routes.filter((c) => c.data?.display);
    this.navLinks.forEach((a) => {
      a.children = a.children?.filter((b) => b.data?.display);
      a.children?.forEach((c) => {
        c.children = c.children?.filter((d) => d.data?.display);
      });
    });
  }
  ngOnInit() {}
}
