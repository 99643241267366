import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";

@Injectable({ providedIn: "root" })
export class CommonService {
  private emailUrl = "/assets/mail.php";

  constructor(private newHttp: HttpClient) {}
  sendMail(form): Observable<any> {
    return this.newHttp.post(this.emailUrl, form);
  }
  getNewsAndAlerts(): Observable<any> {
    return this.newHttp.get<any[]>("assets/data/newsAndAlerts.json");
  }
  getStudentLifeData(): Observable<any> {
    return this.newHttp.get<any[]>("assets/data/studentLife.json");
  }
  getFile(path: string): Observable<any> {
    return this.newHttp.get<any>(path);
  }
  getHomeImages(): Observable<any> {
    return this.newHttp.get<any[]>("assets/home/slideshow.json");
  }
  getResidentialCourses(): Observable<any> {
    return this.newHttp.get<any[]>("assets/data/residentialCourses.json");
  }
  getExtensionCourses(): Observable<any> {
    return this.newHttp.get<any[]>("assets/data/extensionCourses.json");
  }
  getGalleryImages(folderName: string): Observable<any> {
    return this.newHttp.get<IGallery[]>("assets/gallery/gallery.json").pipe(
      map((a) => {
        return a.find((x) => x.folderName == folderName)?.data || [];
      })
    );
  }
}

export interface IGallery {
  folderName: string;
  data: any[];
}
