<div class="contants container">
  <!-- Section: Team v.1 -->
  <section class="team-section">

    <!-- Section heading -->
    <h4 class="section-heading h4 pt-4 bq-title">Our faculty</h4>
    <br>
    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-4 col-md-6 mb-lg-4 mb-4 align-items-start" *ngFor="let faculty of faculties">
        <div class="row align-items-start">
          <div class="avatar">
            <img [src]="'assets/faculty/' + faculty.img" class="rounded z-depth-1" [alt]="faculty.name">
          </div>
          <div class="col">
            <h5 class="font-weight-bold mb-1">{{faculty.name}}</h5>
            <div class="orange-text">
              <strong>{{faculty.designation}}</strong>
            </div>

            <small class="text-uppercase blue-text">{{faculty.education}}</small>

          </div>
        </div>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!-- Section: Team v.1 -->
</div>
