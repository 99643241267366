<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title"> Our Alumni </h4>
    <p class="text-justify">
      Over 1600 graduates of YCLT are now involved in various types of ministry in different parts of India, Nepal, Bangladesh,
      Myanmar, Singapore, U.K. and USA. Majority of our graduates are serving as Missionaries, Native Evangelists, Pastors,
      Teachers, Child Evangelists, Youth Workers, and Social workers. Many of our graduates are ordained and serve as senior
      pastors in several main line churches. At least twenty of our graduates have started their own ministry with intension
      to reach the unreached with gospel of Jesus Christ.
    </p>
  </blockquote>
</div>