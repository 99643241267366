<div class="contants container">
  <h4 class="section-heading h4 pt-4">Calendar of Events</h4>
  <br />
  <!-- <ng-fullcalendar #ucCalendar [options]="calendarOptions" (viewRender)="viewRender()" (eventClick)="eventClick($event.detail)"
        (clickButton)="clickButton($event.detail)" (click)="this.title ? basicModal.show() : null">
    </ng-fullcalendar> -->
  <full-calendar
    defaultView="dayGridMonth"
    [themeSystem]="'litera'"
    [header]="{
      left: 'today',
      center: 'title',
      right: 'dayGridMonth,prev,next'
    }"
    [plugins]="calendarPlugins"
    [weekends]="calendarWeekends"
    [events]="calendarEvents"
  ></full-calendar>
  <!-- <div
    mdbModal
    #basicModal="mdb-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: false, ignoreBackdropClick: true }"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="basicModal.hide(); resetTitle()"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">Details</h4>
        </div>
        <div class="modal-body">
          {{ title }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn primary-color waves-light"
            aria-label="Close"
            (click)="basicModal.hide(); resetTitle()"
            mdbRippleRadius
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>
