import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../services/common.service";
import { Router } from "@angular/router";
import { GalleryItem, Gallery } from "@ngx-gallery/core";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  @ViewChild("newsModal", { static: true })
  public newsModal;
  // slideShow: { imgSrc: string; title: string }[];
  slideShow: GalleryItem[];
  currentNews: { title: string; details: any[]; date: any };
  newsAndAlerts: any[] = [];
  constructor(
    private commonService: CommonService,
    private router: Router,
    public gallery: Gallery
  ) {
    this.currentNews = { title: "", details: [], date: new Date() };
  }
  showModal(i) {
    this.currentNews = i;
    this.newsModal.show();
  }
  ngOnInit() {
    this.commonService.getNewsAndAlerts().subscribe((res) => {
      this.newsAndAlerts = res;
      this.newsAndAlerts.sort((a, b) =>
        new Date(a.date) > new Date(b.date)
          ? -1
          : new Date(a.date) < new Date(b.date)
          ? 1
          : 0
      );
    });
    this.commonService.getHomeImages().subscribe((res) => {
      this.slideShow = res;
    });
    this.gallery.ref("home-gallery", {
      imageSize: "contain",
      autoPlay: true,
    });
  }
  navigate(item) {
    if (item.details.length > 0) {
      this.showModal(item);
    } else {
      item.externalUrl != undefined &&
      item.externalUrl != null &&
      String(item.externalUrl).trim() != ""
        ? window.open(item.externalUrl, "blank")
        : this.router.navigateByUrl(item.target);
    }
  }
}
