import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { CoursesComponent } from "./courses/courses.component";
import { AuxiliariesComponent } from "./auxiliaries/auxiliaries.component";
import { FriendsofYcltComponent } from "./friendsof-yclt/friendsof-yclt.component";
import { YcltHistoryComponent } from "./aboutus/yclt-history.component";
import { BoardMembersComponent } from "./academics/board-members.component";
import { MissionStatementComponent } from "./aboutus/mission-statement.component";
import { AffiliationComponent } from "./aboutus/affiliation.component";
import { StudentsSummaryComponent } from "./aboutus/students-summary.component";
import { BasisComponent } from "./aboutus/basis.component";
import { GuestHouseComponent } from "./auxiliaries/guest-house.component";
import { LibraryComponent } from "./academics/library.component";
import { MsHomeComponent } from "./auxiliaries/ms-home.component";
import { ScholarshipComponent } from "./courses/scholarship.component";
import { ExtensionComponent } from "./courses/extension.component";
import { EventsComponent } from "./events/events.component";
import { ContactComponent } from "./contact/contact.component";
import { FacultyComponent } from "./academics/faculty/faculty.component";
import { AdministrativeStaffComponent } from "./academics/administrative-staff/administrative-staff.component";
import { AdmissionComponent } from "./academics/admission/admission.component";
import { StudentsLifeComponent } from "./events/students-life/students-life.component";
import { HostelLifeAndDisciplineComponent } from "./students-conduct/hostel-life-and-discipline/hostel-life-and-discipline.component";
import { MarriedPersonComponent } from "./students-conduct/married-person/married-person.component";
import { SinglePersonComponent } from "./students-conduct/single-person/single-person.component";
import { GalleryComponent } from "./gallery/gallery.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    data: { title: "Home", display: false },
  },
  {
    path: "about-yclt",
    data: { title: "About Us", display: true },
    children: [
      {
        path: "aims",
        component: AboutusComponent,
        data: { title: "Aims & Objective", display: true },
      },
      {
        path: "yclt-history",
        component: YcltHistoryComponent,
        data: { title: "YCLT History", display: true },
      },
      {
        path: "mission-statement",
        component: MissionStatementComponent,
        data: { title: "Mission Statement", display: true },
      },
      {
        path: "doctrinal-basis-director",
        component: BasisComponent,
        data: { title: "Statement of Faith", display: true },
      },
      {
        path: "board-members",
        component: BoardMembersComponent,
        data: { title: "Board Members", display: true },
      },
      {
        path: "affiliation",
        component: AffiliationComponent,
        data: { title: "Affiliation", display: true },
      },
      {
        path: "students-summary",
        component: StudentsSummaryComponent,
        data: { title: "Students", display: false },
      },
      {
        path: "administrative-staff",
        component: AdministrativeStaffComponent,
        data: { title: "Administrative Staff", display: true },
      },
    ],
  },
  {
    path: "academics",
    data: { title: "Academics", display: true },
    children: [
      {
        path: "faculty",
        component: FacultyComponent,
        data: { title: "Faculty", display: true },
      },
      {
        path: "library",
        component: LibraryComponent,
        data: { title: "Library", display: true },
      },
      {
        path: "admission",
        component: AdmissionComponent,
        data: { title: "Admission", display: true },
      },
      {
        path: "calendar",
        component: EventsComponent,
        data: { title: "Academic Calendar", display: true },
      },
      {
        path: "courses",
        data: { title: "Courses", display: true },
        children: [
          {
            path: "extension",
            component: ExtensionComponent,
            data: { title: "Extension", display: true },
          },
          {
            path: "scholarship",
            component: ScholarshipComponent,
            data: { title: "Students", display: false },
          },
          {
            path: "residential",
            component: CoursesComponent,
            data: { title: "Residential", display: true },
          },
        ],
      },
    ],
  },
  {
    path: "",
    children: [
      {
        path: "guest-house",
        component: GuestHouseComponent,
      },
      {
        path: "fmes",
        component: AuxiliariesComponent,
      },
      {
        path: "ms-home",
        component: MsHomeComponent,
      },
    ],
  },
  {
    path: "events",
    children: [],
  },
  {
    path: "students-conduct",
    data: { title: "Life @ YCLT", display: true },
    children: [
      {
        path: "hostel-life-and-discipline",
        component: HostelLifeAndDisciplineComponent,
        data: { title: "Hostel Life & Discipline", display: true },
      },
      {
        path: "married-person",
        component: MarriedPersonComponent,
        data: { title: "Single Person", display: true },
      },
      {
        path: "single-person",
        component: SinglePersonComponent,
        data: { title: "Married Person", display: true },
      },
      {
        path: "students-life",
        component: StudentsLifeComponent,
        data: { title: "Student's Life", display: true },
      },
      {
        path: "gallery",
        component: GalleryComponent,
        data: { title: "Gallery", display: true },
      },
    ],
  },
  {
    path: "friends",
    data: { title: "Our Partners", display: true },
    children: [
      {
        path: "alumni",
        component: FriendsofYcltComponent,
        data: { title: "Alumni", display: true },
      },
    ],
  },
  {
    path: "contactus",
    component: ContactComponent,
    data: { title: "Contact Us", display: true },
  },
];
