import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "hsweb-faculty",
  templateUrl: "./faculty.component.html",
  styleUrls: ["./faculty.component.scss"]
})
export class FacultyComponent implements OnInit {
  imgLocation: string = "assets/faculty/";
  faculties: any[] = [];
  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.commonService.getFile("assets/data/faculties.json").subscribe(res => {
      this.faculties = res;
    });
  }
}
