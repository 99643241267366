import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-basis",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">Statement of Faith</h4>
      <p>We believe</p>
      <p class="lead">
        In the sixty six books of the Bible as the divinely inspired word of God
        supremely authoritative in all matters of faith and conduct.
      </p>
      <p class="lead">
        In the triune God, Father, Son, and Holy Spirit, almighty and eternal,
        the creator and sustainer of all things, in the deity and incarnation of
        Jesus Christ who through His substitutionary death and bodily
        resurrection has atoned for the sins of all mankind.
      </p>
      <p class="lead">
        In the saving work of the Holy Spirit who convicts, converts, and
        consummates the individual believer in the fellowship of the Church.
      </p>
      <p class="lead">
        That the Church is the body of Christ as well as the people of God. We
        believe in one Holy, Apostolic and Universal Church, which exists to
        glorify the triune God through worship and which encourages its members
        in faith and in growth in Christ-likeness through fellowship, and serves
        the total man through the preaching of the Word and works of love.
      </p>
      <p class="lead">
        That the mission of the Church consists primarily of the evangelization
        of the world and especially of the unreached people, through the
        proclamation of the gospel by word and deed so that individuals and
        families find new life in Christ, and eternal kingdom of God.
      </p>
      <p class="lead">
        In the physical and visible return of Jesus Christ as the Lord and Judge
        of all, to establish finally the eternal kingdom of God.
      </p>
    </blockquote>
  </div>`,
})
export class BasisComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
