import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { CommonService } from "../services/common.service";
import { Observable } from "rxjs";
import { Gallery, GalleryItem } from "@ngx-gallery/core";
import { Lightbox } from "@ngx-gallery/lightbox";
import { map } from "rxjs/operators";

@Component({
  host: {
    class: "page",
  },
  selector: "hsweb-gallery",
  template: `<div class="contants container text-center">
    <div class="btn-group shadow-0" role="group" aria-label="Gallery Folder">
      <button
        type="button"
        class="btn btn-primary"
        *ngFor="let year of galleryYears"
        [ngClass]="{ active: selectedYear == year }"
        (click)="selectGalleryYear(year)"
      >
        {{ year }}
      </button>
    </div>
    <div class="grid">
      <mdb-card
        class="grid-item hoverable"
        *ngFor="let item of space$ | async; let i = index"
        [lightbox]="i"
        gallery="lightbox_gc"
      >
        <mdb-card-body
          class="grid-image"
          [style.backgroundImage]="'url(' + item.data.thumb + ')'"
        ></mdb-card-body>
      </mdb-card>
    </div>
  </div> `,
  styleUrls: ["./gallery.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnInit, OnDestroy {
  code: any;
  space$: Observable<GalleryItem[]>;
  images: string[] = [];
  galleryYears = ["2023-2024", "2022-2023", "2020-2022", "2018-2020"];
  selectedYear: string;
  constructor(
    private commonService: CommonService,
    public gallery: Gallery,
    public lightbox: Lightbox
  ) {
    this.selectedYear = this.galleryYears[0];
  }
  selectGalleryYear(year: string) {
    this.selectedYear = year;
    this.getGallery();
  }
  getGallery() {
    this.space$ = this.commonService.getGalleryImages(this.selectedYear).pipe(
      map((items: GalleryItem[]) => {
        // Load items manually into the lightbox gallery ref
        this.gallery
          .ref("lightbox_gc", {
            imageSize: "contain",
          })
          .load(items);

        return items;
      })
    );
  }
  ngOnInit() {
    this.getGallery();
  }
  ngOnDestroy() {
    this.gallery.ref("lightbox_gc").destroy();
  }
}
