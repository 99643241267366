import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "hsweb-students-life",
  templateUrl: "./students-life.component.html",
  styleUrls: ["./students-life.component.scss"]
})
export class StudentsLifeComponent implements OnInit {
  studentLife: Array<{ title: string; paragraphs: string[] }> = [];
  constructor(private commonService: CommonService) {}

  ngOnInit() {
    this.commonService.getStudentLifeData().subscribe(res => {
      this.studentLife = res;
    });
  }
}
