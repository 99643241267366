import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-aboutus",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">Vision Statement</h4>
      <p class="text-justify">
        Being effective instruments in the integration of Church and Mission
        YCLT’s vision is to multiply Disciples of Christ as Missionaries,
        Pastors, Evangelists and Social workers through its Holistic
        Missiological Education integrating theory and practice.
      </p>
    </blockquote>
    <blockquote class="blockquote bq-warning">
      <h4 class="section-heading h4 pt-4 bq-title">Objects</h4>
      <ul class="text-justify">
        The Objects for which the society is formed are as follows
        <li>
          To maintain and separate Educational Institutions not for profit, but
          solely for the education of pupils of any community, nationality caste
          or creed.
        </li>
        <li>
          To provide for scholastic, Vocational and spiritual training for the
          various Ministries of the Church, particularly in India, with the
          following emphasis
          <ul>
            <li>Bible-centric teaching that is evangelical in Theology.</li>
            <li>Holiness of thought and life, prayer and growth in Christ.</li>
            <li>
              Church’s Mission and Witness in the contemporary world especially
              training cross-cultural gospel workers.
            </li>
          </ul>
        </li>
        <li>
          To provide in the college, an atmosphere of academic excellence and
          spiritual depth, in which are encouraged research, study programmes
          and publications, vital to the understanding and communication of
          Biblical foundations of Christian faith.
        </li>
        <li>
          To give evangelical training in church planting and discipleship. The
          priority is training cross-cultural gospel workers to communicate the
          word of God every unreached people and to plant responsible churches.
        </li>
      </ul>
    </blockquote>
  </div>`,
})
export class AboutusComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
