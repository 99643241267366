import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-friendsof-yclt',
  templateUrl: './friendsof-yclt.component.html',
  styleUrls: ['./friendsof-yclt.component.scss']
})
export class FriendsofYcltComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
