<header>
  <mdb-navbar
    SideClass="navbar navbar-expand-xl primary-color-dark fixed-top navbar-dark scrolling-navbar"
    [containerInside]="false"
  >
    <logo>
      <a class="logo navbar-brand" routerLink="/home">
        <img
          src="assets/log-1.svg"
          style="height: 42px; width: 42px;"
          alt="yclt_logo"
        />
        <strong>
          <span class="hide-mobile"
            >Yavatmal College for Leadership Training</span
          >
          <span class="show-mobile">YCLT</span>
        </strong>
      </a>
    </logo>
    <links>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown" dropdown *ngFor="let nav of navLinks">
          <ng-container *ngIf="nav?.children?.length > 0">
            <a dropdownToggle type="select" class="nav-link dropdown-toggle">
              {{ nav.data.title }}
            </a>
            <div class="dropdown-menu dropdown-menu-left" role="menu">
              <ng-container *ngFor="let subNav of nav.children">
                <div
                  class="divider dropdown-divider"
                  *ngIf="subNav.children?.length > 0"
                ></div>
                <a
                  class="dropdown-item"
                  [routerLink]="
                    subNav.children?.length > 0
                      ? ''
                      : nav.path + '/' + subNav.path
                  "
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [ngClass]="{ disabled: subNav.children?.length > 0 }"
                  >{{ subNav.data.title }}</a
                >
                <a
                  *ngFor="let subNav2 of subNav?.children"
                  class="dropdown-item"
                  [routerLink]="
                    nav.path + '/' + subNav.path + '/' + subNav2.path
                  "
                  routerLinkActive="active"
                  >{{ subNav2.data?.title }}</a
                >
              </ng-container>
            </div>
          </ng-container>
          <a class="nav-link" *ngIf="!nav.children" [routerLink]="nav.path">
            {{ nav.data.title }}
          </a>
        </li>
      </ul>
    </links>
    <!-- <links>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="contactus">Contact Us</a>
        </li>
      </ul>
    </links> -->
  </mdb-navbar>
</header>
<div style="padding-bottom: 4rem; min-height: calc(100vh - 56px);">
  <router-outlet> </router-outlet>
</div>
<footer
  class="page-footer primary-color background-color-wight col-12 d-flex flex-row justify-content-center flex-wrap py-3"
>
  <div>
    © 2018 Copyright:
    <a href="http://www.yclt.org">YCLT</a>
  </div>
  <div class="hide-mobile" style="flex: auto;"></div>
  <div>
    Website by
    <a href="http://www.hotwanisolutions.com/"> Hotwani Solutions </a>
  </div>
</footer>
