<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title">Single Person</h4>
    <p class="text-justify">We disapprove of single men and single women developing or expressing any kind of emotional attachment
      to other students during their training period in YCLT.</p>
    <p>Private conversation through cell phones or correspondence or exchange of gifts (in kind or cash) between a single
      man and single woman is not allowed.</p>
    <p>Single men/women students are not allowed to enter the opposite hostel premises</p>
    <p>Taking photographs with opposite sex individually is prohibited.</p>
  </blockquote>
</div>
