import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hsweb-hostel-life-and-discipline',
  templateUrl: './hostel-life-and-discipline.component.html',
  styleUrls: ['./hostel-life-and-discipline.component.scss']
})
export class HostelLifeAndDisciplineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
