import { Component, OnInit } from "@angular/core";

@Component({
  selector: "hsweb-administrative-staff",
  templateUrl: "./administrative-staff.component.html",
  styleUrls: ["./administrative-staff.component.scss"]
})
export class AdministrativeStaffComponent implements OnInit {
  staff: any[] = [
    {
      name: "Baburajan Tharoli",
      designation: "Bursar",
      img: "btharoli.jpg"
    },
    {
      name: "Mr.Jayant Deshmukh",
      designation: "Cashier",
      img: "jdeshmukh.jpg"
    },
    {
      name: "Mrs.Swarna Jyothi Tharoli",
      designation: "Assiatant Librarian",
      img: "stharoli.jpg"
    },
    {
      name: "Mr.Vijay Arun Macwann",
      designation: "Assistant Librarian",
      img: "vmacwann.jpg"
    },
    {
      name: "Mr.Shankar Arjuna",
      designation: "Peon",
      img: "sarjuna.jpg"
    }
  ];
  constructor() {}

  ngOnInit() {}
}
