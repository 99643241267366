<div class="contants container">
  <section class="section">
    <h2 class="section-heading h3 pt-4">Contact us</h2>
    <div class="row">
      <div class="col-md-8 col-xl-9">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="contactForm" novalidate>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <div class="md-form">
                      <i class="fa fa-user prefix"></i>
                      <input mdbActive type="text" id="name" formControlName="name" class="form-control" required>
                      <label for="name">Your name</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <div class="md-form">
                      <i class="fa fa-envelope prefix"></i>
                      <input mdbActive type="email" class="form-control" id="email" formControlName="email" required>
                      <label for="email">Your email</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form">
                    <div class="md-form">
                      <i class="fa fa-mobile prefix"></i>
                      <input mdbActive type="text" id="mobileNumber" formControlName="mobileNumber" class="form-control" required minlength="10"
                        maxlength="10">
                      <label for="mobileNumber">Your Number</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <div class="md-form">
                      <i class="fa fa-tag prefix"></i>
                      <input mdbActive type="text" id="subject" formControlName="subject" class="form-control" required>
                      <label for="subject">Subject</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form">
                    <i class="fa fa-pencil prefix"></i>
                    <textarea mdbActive type="text" id="message" formControlName="message" class="md-textarea" required></textarea>
                    <label for="message">Message</label>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary waves-light" (click)="sendMail()" [disabled]="contactForm.invalid" type="submit">Send</button>
              </div>
              <div class="status" id="status"></div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3">
        <!-- <br> -->
        <blockquote class="blockquote bq-primary">
          <h6 class="section-heading bq-title">Campus</h6>
          <h6>
            <i class="fa fa-map-marker"></i> Seminary Compound, Yavatmal - 445001 Maharashtra</h6>
          <h6>
            <i class="fa fa-phone"></i> 07232-244401,244597</h6>
          <h6>
            <i class="fa fa-envelope"></i> info@yclt.org</h6>
        </blockquote>
        <blockquote class="blockquote bq-warning">
          <h6 class="section-heading  bq-title">Director</h6>
          <h6>
            <i class="fa fa-user prefix"></i>
            <b>Rev. Dr. Girish J. Khatri</b>
          </h6>
          <h6>
            <i class="fa fa-envelope prefix"></i> director@yclt.org</h6>
        </blockquote>
        <blockquote class="blockquote bq-primary">
          <h6 class="section-heading  bq-title">Registrar</h6>
          <h6>
            <i class="fa fa-user prefix"></i>
            <b>Dinesh Patel</b>
          </h6>
          <h6>
            <i class="fa fa-envelope prefix"></i> registrar@yclt.org</h6>
        </blockquote>
      </div>
    </div>
  </section>
</div>