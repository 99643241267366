import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-house',
  templateUrl: './guest-house.component.html',
  styleUrls: ['./guest-house.component.scss']
})
export class GuestHouseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
