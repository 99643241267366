import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-affiliation",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">
        Affiliation and Accreditation
      </h4>
      <p class="text-justify">
        YCLT has got provisional affiliation for three years from the Senate of
        Serampore College (University) for its B.Miss and B.Th degree Course,
        which has commenced from June 2018.
      </p>
      <p class="text-justify">
        YCLT is affiliated to Indian Institute of Inter Cultural Studies,
        Bangalore, an accrediting Senate for ministerial training. Our M.Div.
        and B.Th. degrees, diplomas and certificates are awarded by IIICS.
      </p>
    </blockquote>
  </div> `,
})
export class AffiliationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
