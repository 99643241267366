import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-students-summary",
  template: `<div class="contants container">
    <blockquote class="blockquote bq-primary">
      <h4 class="section-heading h4 pt-4 bq-title">Student's Summary</h4>
      <p class="text-justify">
        Right now we have eighteen students enrolled in various courses. All of
        them have joined the college with genuine salvation experience and sense
        of God’s call for ministry. Following is the statistical information
      </p>
      <div class="row text-center">
        <div class="col-lg-4 col-md-6 mb-r">
          <div style="display: block;">
            <canvas
              mdbChart
              [chartType]="chartType"
              [data]="chartData"
              [labels]="chartLabels"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"
            >
            </canvas>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-r">
          <div style="display: block;">
            <canvas
              mdbChart
              [chartType]="chartType"
              [data]="chartData1"
              [labels]="chartLabels1"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"
            >
            </canvas>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-r">
          <div style="display: block;">
            <canvas
              mdbChart
              [chartType]="chartType"
              [data]="chartData2"
              [labels]="chartLabels2"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"
            >
            </canvas>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-r">
          <div style="display: block;">
            <canvas
              mdbChart
              [chartType]="chartType"
              [data]="chartData3"
              [labels]="chartLabels3"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"
            >
            </canvas>
          </div>
        </div>
      </div>
    </blockquote>
  </div>`,

})
export class StudentsSummaryComponent implements OnInit {
  public chartType: string = "pie";
  public chartData: Array<any> = [11, 8];
  public chartData1: Array<any> = [14, 4];
  public chartData2: Array<any> = [11, 7];
  public chartData3: Array<any> = [2, 9];
  public chartLabels: Array<any> = [
    "Sponsored students",
    "Independent students",
  ];
  public chartLabels1: Array<any> = ["Single Students", "Married Students"];
  public chartLabels2: Array<any> = ["Man Students", "Woman Students"];
  public chartLabels3: Array<any> = [
    "State Representation",
    "Organization Representation",
  ];
  public chartColors: Array<any> = [
    {
      hoverBorderColor: ["rgba(0, 0, 0, 1)", "rgba(0, 0, 0, 1)"],
      hoverBorderWidth: 0,
      backgroundColor: ["#90caf9", "#ffcc80"],
      hoverBackgroundColor: ["#90caf9", "#ffcc80"],
    },
  ];
  public chartOptions: any = {
    responsive: true,
  };
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}
  constructor() {}
  ngOnInit() {}
}
