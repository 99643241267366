import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, Injectable } from '@angular/core';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CoursesComponent } from './courses/courses.component';
import { AuxiliariesComponent } from './auxiliaries/auxiliaries.component';
import { FriendsofYcltComponent } from './friendsof-yclt/friendsof-yclt.component';
import { YcltHistoryComponent } from './aboutus/yclt-history.component';
import { BoardMembersComponent } from './academics/board-members.component';
import { MissionStatementComponent } from './aboutus/mission-statement.component';
import { AffiliationComponent } from './aboutus/affiliation.component';
import { StudentsSummaryComponent } from './aboutus/students-summary.component';
import { BasisComponent } from './aboutus/basis.component';
import { GuestHouseComponent } from './auxiliaries/guest-house.component';
import { LibraryComponent } from './academics/library.component';
import { MsHomeComponent } from './auxiliaries/ms-home.component';
import { ScholarshipComponent } from './courses/scholarship.component';
import { ExtensionComponent } from './courses/extension.component';
import { EventsComponent } from './events/events.component';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';  // <-- #1 import module
import { FullCalendarModule } from '@fullcalendar/angular';
import { FmesComponent } from './auxiliaries/fmes.component';
import { FacultyComponent } from './academics/faculty/faculty.component';
import { AdministrativeStaffComponent } from './academics/administrative-staff/administrative-staff.component';
import { AdmissionComponent } from './academics/admission/admission.component';
import { StudentsLifeComponent } from './events/students-life/students-life.component';
import { HostelLifeAndDisciplineComponent } from './students-conduct/hostel-life-and-discipline/hostel-life-and-discipline.component';
import { MarriedPersonComponent } from './students-conduct/married-person/married-person.component';
import { SinglePersonComponent } from './students-conduct/single-person/single-person.component';
import { GalleryComponent } from './gallery/gallery.component';
declare var Hammer: any;
import { GalleryModule } from  '@ngx-gallery/core';
import { LightboxModule } from  '@ngx-gallery/lightbox';
import { GallerizeModule } from  '@ngx-gallery/gallerize';
import { RouterModule } from '@angular/router';
import { routes } from "./routes"
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    CoursesComponent,
    AuxiliariesComponent,
    FriendsofYcltComponent,
    YcltHistoryComponent,
    BoardMembersComponent,
    MissionStatementComponent,
    AffiliationComponent,
    StudentsSummaryComponent,
    BasisComponent,
    GuestHouseComponent,
    LibraryComponent,
    MsHomeComponent,
    ScholarshipComponent,
    ExtensionComponent,
    EventsComponent,
    ContactComponent,
    FmesComponent,
    FacultyComponent,
    AdministrativeStaffComponent,
    AdmissionComponent,
    StudentsLifeComponent,
    HostelLifeAndDisciplineComponent,
    MarriedPersonComponent,
    SinglePersonComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
