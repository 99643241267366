<div class="contants container">
  <blockquote class="blockquote bq-primary">
    <h4 class="section-heading h4 pt-4 bq-title">Hamilton Library</h4>
    <br>
    <figure>
      <figcaption>General and References Section</figcaption>
      <img class="img-fluid d-block w-100 show-mobile" src="assets/library/gen_ref_section.jpg" alt="General Reference Section">
    </figure>
    <br>
    <figure>
      <figcaption>Periodicals Section</figcaption>
      <img class="img-fluid d-block w-100 show-mobile" src="assets/library/periodicals.jpg" alt="Periodicals">
    </figure>
    <br>
    <figure>
      <figcaption>Students' Computer Room</figcaption>
      <img class="img-fluid d-block w-100 show-mobile" src="assets/library/computer_room.jpg" alt="Computer Room">
    </figure>
    <p class="text-justify">A collection of books, periodicals and other unpublished materials organized and maintained for
      use. Libraries have been identified as one of the key elements for open access to information, upgrade the knowledge
      and to enrich discipline. Even in the YCLT, the huge library block is well furnished with adequate furniture, equipments
      and modern technological facilities. The Library has over fifteen thousand books. Collection includes Bible dictionaries,
      commentaries, encyclopedia, interpreters, Biblical atlas and other reference books. Besides these References, books
      on theology, Biblical Studies, Christian doctrines, Christian Counselling, Christian Education, Homiletics, missiology,
      history of mission, anthropology and church growth and church planting, Christian life and ministry, culture and religions,
      methodology, language, and books on general studies are available. The books available also in another medium, like,
      Hindi, Marathi, Gujarati etc. The Library also receives more than seventy magazines, journals and other periodicals.
      Computers are made available for students use in the library. Efforts are being made to establish e-library.
    </p>
  </blockquote>
</div>
