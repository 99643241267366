import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-board-members",
  templateUrl: "./board-members.component.html",
  styleUrls: ["./board-members.component.scss"],
})
export class BoardMembersComponent implements OnInit {
  boardMembers: { name; designation; designation2?; designation3? }[] = [];
  constructor() {
    this.boardMembers = [
      {
        name: "Rev. Dr. D. B. Kulothungan",
        designation: "Chairman",
        designation2:
          "Founding and General Secretary Maharashtra Village Ministry",
      },
      {
        name: "Rev. Pankaj Salve",
        designation: "Vice Chairman",
        designation2: "Manager, India Every Home Crusade",
      },
      {
        name: "Rev. Dr. Robert V. Gaikwad",
        designation: "Secretary",
        designation2: "Suprintendent, Boys Christian Home CCCWI, Basamati",
      },
      {
        name: "Mr. Nitin V. Nathar",
        designation: "Treasurer",
        designation2: "President, Friends Education Society",
        designation3: "Principal, Nathar English School Yavatmal",
      },
      { name: "Mrs. Sulbhabai Jacob", designation: "Member" },
      {
        name: "Mr. Abhijit Parage",
        designation: "Member",
        designation2: "Administrator, Dinbandhu Ministries",
      },
      {
        name: "Rev. Dr. Girish J. Khatri",
        designation: "Principal/Director",
      },
    ];
  }

  ngOnInit() {}
}
