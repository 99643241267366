<div class="contants container">
  <section class="section team-section pb-3">
    <h3 class="section-heading h3 pt-4 bq-title">Students' Life</h3>
    <br />

    <blockquote
      *ngFor="let item of studentLife; let odd = odd; let even = even"
      class="blockquote"
      [ngClass]="{
        'bq-primary': odd,
        'bq-warning': even
      }"
    >
      <h4 class="section-heading h4 pt-4 bq-title">
        {{ item.title | titlecase }}
      </h4>
      <p class="text-justify" *ngFor="let p of item.paragraphs">
        {{ p }}
      </p>
    </blockquote>
  </section>
</div>
